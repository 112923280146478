<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="checkPermissions(['INSTALLMENT_INSERT']) ? ' ' : ''"
        >
          <template v-slot:toolbar>
            <div class="row">
              <router-link
                to="/accounting/installments/add"
                v-if="checkPermissions(['INSTALLMENT_INSERT'])"
              >
                <button
                  v-bind:style="btnCreate"
                  type="button"
                  class="btn btn-primary font-weight-bolder btn-sm"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                  mới
                </button>
              </router-link>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <b-input
                    placeholder="Tìm kiếm"
                    v-model="search"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-4 btn-sm"
                    v-on:input="onFilter"
                  ></b-input>
                  <div class="col-md-8" style="padding-right: 0px">
                    <div class="d-flex justify-content-end"></div>
                  </div>
                </div>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="installments"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkPermissions(['INSTALLMENT_UPDATE'])"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermissions(['INSTALLMENT_DELETE'])"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số dịch vụ trả góp:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchInstallments()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
import { checkPermissions } from '../../../utils/saveDataToLocal';

export default {
  data() {
    return {
      installments: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      jobName: '',
      storeName: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      search: '',
      employees: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã đối tượng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'phone',
          label: 'Điện thoại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'creator',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            return value?.fullName;
          },
        },
        {
          key: checkPermissions(['INSTALLMENT_UPDATE', 'INSTALLMENT_DELETE'])
            ? 'actions'
            : '',
          label: '',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Dịch vụ trả góp', route: 'installments' },
      { title: 'Danh sách dịch vụ trả góp' },
    ]);

    if (this.$route.params.createInstallmentSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.createInstallmentSuccess = false;
    }
    if (this.$route.params.updateInstallmentSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateInstallmentSuccess = false;
    }
  },
  created() {
    this.fetchInstallments();
  },
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'update-installment',
        query: { id: item.id },
      });
    },
    fetchInstallments: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      let params = {
        page: this.page,
        limit: 10,
        name: this.search,
      };

      ApiService.setHeader();
      ApiService.query('/installments', { params }).then((response) => {
        const data = response.data.data;

        this.installments = data.data.map((item, index) => ({
          stt: index + 1,
          ...item,
        }));

        this.totalPages = data.paginations.totalPages;
        this.currentPage = data.paginations.currentPage;
        this.totalItems = data.paginations.total;
      });
    },
    showDeleteSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Xóa nhân viên thành công!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    deleteItem: async function (item) {
      ApiService.patch('installments' + '/' + item.id).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchInstallments();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa tài khoản!',
        text: 'Bạn có chắc muốn xóa tài khoản này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('EMPLOYEE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('EMPLOYEE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-installment',
      });
      this.fetchInstallments();
    },
    debounceInput: decounce(function () {
      this.searchEmployee();
    }, 2000),
    checkPermissions,
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>
